import React from "react";
import {
  EditGuesser,
  ListGuesser,
  FieldGuesser,
  ShowGuesser,
  CreateGuesser
} from "@api-platform/admin";
import InputGuesser from '../InputGuesser';
import { RoutesField } from '../fields/RouteFields'

export const RouteEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser source="name" />
    <InputGuesser source="route" />
    <InputGuesser source="redirect" optionText="route" searchKey="route" />
  </EditGuesser>
);

export const RouteList = props => (
  <ListGuesser {...props}>
    <FieldGuesser source={"name"} />
    <FieldGuesser source={"route"} />
  </ListGuesser>
);

export const RouteShow = props => (
  <ShowGuesser {...props}>
    <FieldGuesser source={"name"} addLabel={true} />
    <FieldGuesser source={"route"} addLabel={true} />
    <RoutesField source={"redirect"} addLabel={true} />
  </ShowGuesser>
);

export const RouteCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source="name" />
    <InputGuesser source="route" />
    <InputGuesser source="redirect" optionText="route" searchKey="route" />
  </CreateGuesser>
);
