import { InputGuesserComponent as BaseInputGuesserComponent } from '@api-platform/admin/lib/InputGuesser'
import IntrospectQuery from '@api-platform/admin/lib/IntrospectQuery'
import React, { Fragment } from 'react'
import {
  ReferenceInput,
  required,
  ReferenceArrayInput,
  AutocompleteInput,
  AutocompleteArrayInput
} from 'react-admin';
import PropTypes from 'prop-types'
import { getReferenceNameField } from '@api-platform/admin/lib/docsUtils'

const InputGuesserComponent = ({fields, resourceSchema, ...props}) => {
  const field = fields.find(({name}) => name === props.source);
  if (!field) {
    console.error(
      `Field ${props.source} not present inside the api description for the resource ${props.resource}`,
    );

    return <Fragment />;
  }

  const validate =
    !props.validate && field.required ? [required()] : props.validate;
  const searchKey = props.searchKey || 'id'
  const searchFn = searchText => ({ [searchKey]: searchText })
  if (null !== field.reference) {
    if (1 === field.maxCardinality) {
      return (
        <ReferenceInput
          key={field.name}
          label={field.name}
          reference={field.reference.name}
          source={field.name}
          validate={validate}
          filterToQuery={searchFn}
          {...props}
          allowEmpty>
          <AutocompleteInput optionText={props.optionText || getReferenceNameField(field.reference)}/>
        </ReferenceInput>
      );
    }
    return (
      <ReferenceArrayInput
        key={field.name}
        label={field.name}
        reference={field.reference.name}
        source={field.name}
        validate={validate}
        filterToQuery={searchFn}
        {...props}
        allowEmpty>
        <AutocompleteArrayInput optionText={props.optionText || getReferenceNameField(field.reference)} />
      </ReferenceArrayInput>
    );
  }
  return BaseInputGuesserComponent({fields, resourceSchema, ...props})
}

const InputGuesser = props => (
  <IntrospectQuery
    component={InputGuesserComponent}
    includeDeprecated={true}
    {...props}
  />
);
InputGuesser.propTypes = {
  source: PropTypes.string.isRequired,
};

export default InputGuesser
