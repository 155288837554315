import React from 'react'
import { ReferenceArrayField, SingleFieldList, ChipField, ReferenceField } from 'react-admin';
import PropTypes from 'prop-types'
import IntrospectQuery from '@api-platform/admin/lib/IntrospectQuery'

const IntrospectQueryComponent = (props, Field) => (
  <IntrospectQuery
    component={Field}
    includeDeprecated={true}
    {...props}
  />
)
const Field = ({fields, resourceSchema, ...props}) => {
  const field = fields.find(f => f.name === props.source);
  const referenceNameField = props.chipSource || "route";
  if (1 === field.maxCardinality) {
    return (
      <ReferenceField reference={field.reference.name} {...props} allowEmpty>
        <ChipField source={referenceNameField}/>
      </ReferenceField>
    )
  }
  return (
    <ReferenceArrayField reference={field.reference.name} {...props}>
      <SingleFieldList>
        <ChipField source={referenceNameField} key={referenceNameField} />
      </SingleFieldList>
    </ReferenceArrayField>
  )
}

export const RouteField = props => {
  return IntrospectQueryComponent(props, Field)
}

RouteField.propTypes = {
  source: PropTypes.string.isRequired,
};

export const RoutesField = RouteField

export default RouteField
